<template>
  <div class="app-card">
    <!-- <a-button type="primary" @click="test">
      Test
    </a-button> -->
    <a-input-group compact style="padding-bottom:20px;">
      <a-range-picker  v-model="selectPicker"/>
      <a-button type="primary" @click="getDatas">查询</a-button>
    </a-input-group>
    
    <v-chart class="chart" :option="option" />
  </div>
</template>

<script>
import moment from 'moment'
// import axios from 'axios'
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { CarOrders } from '@/api'
import {
    TooltipComponent,
    GridComponent,
    LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([TooltipComponent, GridComponent, LegendComponent, BarChart, CanvasRenderer]);

export default {
  name: "Dashboard",
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  mounted() {
    this.selectPicker = [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf('month').format("YYYY-MM-DD")]
    this.getDatas()
  },
  data() {
    return {
      selectPicker: [],
      option: {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: ['保费', '积分']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            data: []
        },
        series: [
            {
                name: '保费',
                type: 'bar',
                label: {
                    show: true,
                    position: 'inside'
                },
                data: []
            },
            {
                name: '积分',
                type: 'bar',
                label: {
                    show: true,
                    position: 'inside'
                },
                data: []
            }
        ]
      }
    };
  },
  methods: {
    async test() {
      const apiRes = await this.$axios.get('/Test/Test')
      console.log(apiRes)
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      if (apiRes.data.ApiType === "FormData") {
        this.$postForm(apiRes.data.ApiUrl, apiRes.data.PostInfo)
      }
    },
    PostForm(URL, PARAMTERS) {
         //创建form表单
         var temp_form = document.createElement("form");
         temp_form.action = URL;
         //如需打开新窗口，form的target属性要设置为'_blank'
         temp_form.target = "_self";
         temp_form.method = "post";
         temp_form.style.display = "none";
         //添加参数
         for (var item in PARAMTERS) {
             var opt = document.createElement("textarea");
             opt.name = item;
             opt.value = PARAMTERS[item];
             temp_form.appendChild(opt);
         }
         document.body.appendChild(temp_form);
         //提交数据
         temp_form.submit();
     },
    async getDatas() {
      console.log(this.selectPicker)
      let createdAt = []
      if (this.selectPicker && this.selectPicker.length === 2) {
        createdAt = [moment(this.selectPicker[0]).format("YYYY-MM-DD"), moment(this.selectPicker[1]).format("YYYY-MM-DD")]
      }
      const apiRes = await this.$axios.post(`${CarOrders}/GetPremiumAndScoreByMonth`, {
        createdAt
      })
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            data: ['保费', '积分']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            data: []
        },
        series: [
            {
                name: '保费',
                type: 'bar',
                label: {
                    show: true,
                    position: 'inside'
                },
                data: []
            },
            {
                name: '积分',
                type: 'bar',
                label: {
                    show: true,
                    position: 'inside'
                },
                data: []
            }
        ]
      }
      for (const key of Object.keys(apiRes.data)) {
        const item = apiRes.data[key]
        option.yAxis.data.push(item.salesman)
        option.series[0].data.push(item.amount)
        option.series[1].data.push(item.outScore)
      }
      this.$set(this, 'option', option)
    }
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>